import './App.css';
import CustomMap from './components/Map/CustomMap'
import Activity from "./components/Activity";
import Me from "./components/Me/Me";
import About from "./components/About/About";
import NewPlaceForm from "./components/NewPlaceForm";
import { Layout, Menu, Affix, Avatar, Modal, Button, Image, Tooltip, Space } from 'antd';
import { Routes, Link, Route } from "react-router-dom";
import { useState, useEffect, useLayoutEffect } from "react";
import { Notifications } from 'react-push-notification';
import {
  ProfileOutlined,
  QuestionCircleOutlined,
  UserOutlined,
  CarOutlined
} from '@ant-design/icons';

import "antd/dist/antd.css";
import LoginPopup from "./components/LoginPopup/LoginPopup";
import React from 'react';
import { useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";
import MailingListPopup from './components/MailingListPopup';

const { Content, Footer, Header } = Layout;

const App = () => {

  const identity = useSelector((state) => state.auth);
  const user = useSelector((state) => state.user);
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const navigate = useNavigate();

  /**
   * 
   */
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    window.addEventListener("beforeinstallprompt", (e) => {
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      e.preventDefault();
      // Stash the event so it can be triggered later.
      setDeferredPrompt(e);
      if (identity.isLogged === true) {
        setIsModalOpen(true);
      }
    });
  });

  const installApp = () => {
    // Show the prompt
    deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === "accepted") {
        console.log("User accepted the install prompt");
        setIsModalOpen(false);
      } else {
        console.log("User dismissed the install prompt");
        //setIsModalOpen(false);
      }
      setDeferredPrompt(null);
    });
  };

  return (
    <>
      <Notifications />
      <Layout style={{ minHeight: '100vh' }}>

        <Layout className="site-layout">
          <Header style={{ position: 'fixed', top: 0, zIndex: 900, width: "100%" }}>


            <div className='container m-0 p-0 mt-2'>
              <div className='row no-gutters'>
                <div className='col-7 text-left'>
                  <Link to="/">
                    <span className="p-0 logo">ODPADO</span>
                  </Link>
                </div>
                <div className='col-5 text-sm-end' style={{ lineHeight: "120%" }}>
                  <Space>
                    <a style={{ textTransform: "uppercase", fontSize: "10px", textShadow: "1px 1px 1px #fff", color: "#000", textAlign: "center", display: "block" }} target='_blank' href='https://buycoffee.to/michalszafranski/'>
                      <u>Postaw kawę</u>
                    </a>
                    <MailingListPopup />
                    <Link to="/me">
                      <Avatar className='shadow' src={identity.user.photoUrl} size={50} alt={identity.user.shortName} />
                    </Link>
                  </Space>
                </div>
              </div>
              <div style={{ width: "100%", maxWidth: "368px", margin: "auto", height: "60px", lineHeight: "100%", textAlign: "center", padding: "5px", display:"none" }}>
                <a target='_blank' href='https://www.michalszafranski.pl/reklama/'>
                  <img src='https://www.szafra.eu/odpadowo/baner-odpado.png' />
                </a>
              </div>
            </div>


          </Header>
          <Content style={{ minHeight: '100vh' }}>
            <LoginPopup></LoginPopup>
            <Routes>
              <Route path="/" element={<CustomMap />} />
              <Route path="activity" element={<Activity />} />
              <Route path="me" element={<Me />} />
              <Route path="about" element={<About />} />
            </Routes>
          </Content>
          <Affix style={{ position: 'fixed', bottom: 0, zIndex: 900, width: "100%" }}>
            <Footer style={{ padding: "0px", textAlign: 'center' }}>
              <Menu theme="light" defaultSelectedKeys={['1']} mode="horizontal" style={{ padding: "10px 0px", position: "relative", display: "flex", justifyContent: "center" }}  >
                <Menu.Item key="1">
                  <Link to="/">
                    <CarOutlined />
                  </Link>
                </Menu.Item>
                <Menu.Item key="2">
                  <Link to="/activity">
                    <ProfileOutlined />
                  </Link>
                </Menu.Item>
                <Menu.Item key="5">
                  <NewPlaceForm />
                </Menu.Item>
                <Menu.Item key="3">
                  <Link to="/me">
                    <UserOutlined />
                  </Link>
                </Menu.Item>
                <Menu.Item key="4">
                  <a href='/aplikacja/'>
                  <QuestionCircleOutlined />
                  </a>
                </Menu.Item>
              </Menu>
            </Footer>
          </Affix>
        </Layout>

      </Layout>
      <Modal
        title={false}
        visible={isModalOpen}
        footer={(null, null)}
        onCancel={handleCancel}
        centered={true}
      >
        <Image src="/img/man-putting-waste-in-garbage-7390789-5992903.webp"></Image>
        <p className='mt-4'>
          <h1>Zainstaluj Odpado w telefonie</h1>
          Dzięki temu podczas spaceru natychmiast oflagujesz na mapie
          miejsca, które wymagają uporządkowania, a także będziesz mógł odebrać nagrody finansowe za uprzątniecie
          miejsc zgłoszonych przez innych użytkowników. Naprawdę warto!
        </p>
        {deferredPrompt && (
          <Button type='primary' size='large' onClick={installApp}>
            Zainstaluj teraz
          </Button>
        )}

      </Modal>

    </>
  );
}

export default App;
